import React, { Component } from 'react';

class Footer extends Component {
  render() { 
    return (
      <footer>

<div class="backto-top"> 
            <div> 
                <i data-feather="arrow-up"></i> 
            </div>
        </div>
  </footer>
    );
  }
}

export default Footer;
